
#global-header .block {
    padding-left: 0px;
}

.our_bees {
  color: #FFFFFF;
}

#feature.our_bees p {
  color: #FFFFFF;
}

#feature.our_bees:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
/*  background: linear-gradient(to right, #19110F, #543932); */
  background: #19110F;
  opacity: 0.5;
}


#intro .block img.lightbox-thumb {
  width:auto;
}

#intro .block img.lightbox-thumb-right {
  width:auto;
  float:right;
  padding-left:20px;
  padding-right:0px;
}

#intro .block img.lightbox-thumb-left {
  width:auto;
  float:left;
  padding-right:20px;
  padding-left:0px;
}

#intro .block img.lightbox-thumb-center {
  width:auto;
  text-align: center;
}

.PageNavigation {
  font-size: 14px;
  display: block;
  width: auto;
  overflow: hidden;
}

.PageNavigation a {
  display: block;
/*  width: 50%; */
  float: left;
  margin: 1em 0;
}

.PageNavigation .next {
  text-align: right;
  float: right;
}

.post-area a:hover {
  text-decoration: underline;
  color: #cccccc;
}

.post-area a:link.continue-reading, .post-area a:visited.continue-reading {
  font-size:14px;
}

.post #intro {
  padding-top: 0px;
  border: 1px dotted #ddd;
}
