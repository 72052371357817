/*
 * This css file includes styles added in the jekyll port of this theme.
 * To find the original style.css that ships with Airspace, read airspace.css
 *
 */

 .italic {
   font-style: italic;
 }

 .bold {
   font-weight: bold;
 }

 .center-text {
   text-align: center;
 }

.post-area {
  padding: 40px;
  width: 100%;
  min-height: 150px;
  background-color: white;
  border-bottom: 1px dotted #ddd;
}

.post-area a:link, .post-area a:visited {
  font-size: 30px;
  color: #333;
}

.post-area a:hover {
  color: #0D0017;
}
